import React, { useEffect, useState } from "react";
import "../UserDeatil/PostDetail.css";
import axios from "../Schemas/API";
import { motion } from "framer-motion";
import Eye from "../Images/eye.png";
import { useLocation } from "react-router";
import { Modal } from "react-bootstrap";
const UserDeatil = () => {
  const [postDeatil, setPostDetail] = useState([]);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [popu, setPopu] = useState([]);
  const [user, setUser] = useState([]);
  // UserDetail API Function..........................................
  const MyToken = localStorage.getItem("TOKEN");
  const route = useLocation();
  const PostData = route?.state?.item;
  const getPostDeatil = async () => {
    try {
      const res = await axios.post(
        "/user/get-detail",
        {
          user_id: PostData.id.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );

      // console.log(res, "Post detail mil gyi");
      setPostDetail(res.data.data.userdata);
    } catch (error) {
    }
  };
  useEffect(() => {
    getPostDeatil();
  }, []);

  // get-by-userid API Function........................................
  const getPostList = async () => {
    try {
      const res = await axios.post(
        "/post/get-by-userid",
        {
          user_id: PostData?.id?.toString(),
          page: "1",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      // console.log(res, "sab user");
      setUser(res.data.data);
    } catch (error) {
    }
  };
  useEffect(() => {
    getPostList();
  }, []);

  const handleUser = (item) => {
    setPopu(item);
    setShow(true);
  };

  // Delete API function here..................................................................
  const PostDelete = async () => {
    try {
      const res = await axios.post(
        "/post/delete",
        {
          id: popu?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setShow(false);
    } catch (error) {
    }
  };
  const Deletepopup = (item) => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Post Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add">
            <div className="flex-delete-popup-refunds">
              <img
                src={postDeatil.image}
                alt=""
                className="Two-img-popu-heres"
              />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{postDeatil.firstname}</span>
                <span>{postDeatil.lastname}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this post?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => PostDelete(item?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />

      <div className="Postdeatil-Main-Componenets">
        <div className="Profile-card">
          <div className="Name-follw-main">
            <img
              src={postDeatil.image}
              alt=""
              className="radius-profile-pic"
              style={{ border: "4px solid red" }}
            />
            <span>{postDeatil.firstname + postDeatil.lastname}</span>
            <span>
              <span>{postDeatil.position_company}</span>
            </span>
            <span>
              <span>{postDeatil.address}</span>
            </span>
            <div className="btn-main-components">
              <button
                className="btn-follow-Name"
                style={{ background: "red" }}
                onClick={() => handleUser(postDeatil)}
              >
                Delete
              </button>
            </div>
          </div>
          <hr
            className="hr-tag"
            style={{ marginTop: "1.5rem", width: "90%" }}
          />
          <div className="Main-link-user-profile">
            {/* {SocialData.map((item, index) => {
            return (
              <> */}
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">Gender</span>
              </div>
              <span className="span-item-Profile">{postDeatil.gender}</span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">Email</span>
              </div>
              <span className="span-item-Profile">{postDeatil.email}</span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">PhoneNumber</span>
              </div>
              <span className="span-item-Profile">
                {postDeatil.country_code}
                {postDeatil.phonenumber}
              </span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">DOB</span>
              </div>
              <span className="span-item-Profile">{postDeatil.dob}</span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">Country</span>
              </div>
              <span className="span-item-Profile">{postDeatil.countr}</span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">State</span>
              </div>
              <span className="span-item-Profile">{postDeatil.state}</span>
            </div>
            <hr className="hr-tag" />

            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">City</span>
              </div>
              <span className="span-item-Profile">{postDeatil.city}</span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">Company Name</span>
              </div>
              <span className="span-item-Profile">
                {postDeatil.company_name}
              </span>
            </div>
            <hr className="hr-tag" />
            <div className="all-link-gaping">
              <div className="title-and-Name">
                <span className="span-item-Profile">Bio</span>
              </div>
              <span className="span-item-Profile">{postDeatil.bio}</span>
            </div>
            <hr className="hr-tag" />
          </div>
        </div>
        <div className="Post-Sub-Deatils">
          <div className="layrout-detail">
            <div className="table-responsive-add" style={{ marginTop: "2rem" }}>
              <span className="two-span-post">Posts</span>
              <div className="easy-add-scroll-new">
                <table>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Like</th>
                      <th>Comment</th>
                      <th>Repost</th>
                      <th>createdAt</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user && user.length > 0 ? (
                      user.map((item, index) => {
                        const serialNumber = index + 1;
                        return (
                          <>
                            <tr>
                              <td className="">{serialNumber}</td>
                              <td>
                                <img
                                  src={item.gallery}
                                  alt=""
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "10px",
                                    border: "1px solid #e6e6e6",
                                  }}
                                />
                              </td>

                              <td className="">{item.desc}</td>
                              <td className="">{item.total_like}</td>
                              <td className="">{item.total_comment}</td>
                              <td>
                                {item.total_repost !== undefined
                                  ? item.total_repost
                                  : "N/A"}
                              </td>
                              {/* <td className="">{item.total_repost}</td> */}
                              <td>{item.createdAt}</td>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "1rem",
                                }}
                              >
                                <button
                                  className="delete-buttons-red"
                                  style={{ border: "1px solid #15ca20" }}
                                >
                                  <img
                                    src={Eye}
                                    alt="Delete"
                                    className="delete-btn-red"
                                  />
                                </button>
                              </div>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <span>Data Not Found</span>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Sr No</th>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Like</th>
                      <th>Comment</th>
                      <th>Repost</th>
                      <th>createdAt</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDeatil;
