import React, { useEffect, useState } from "react";
import "../Post/Postview.css";
import axios from "../Schemas/API";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { isAccordionItemSelected } from "react-bootstrap/esm/AccordionContext";
const Postview = () => {
  const [commnet, setCommnet] = useState([]);
  const [detail, setDetail] = useState([]);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);

  const [popu, setPopu] = useState([]);
  const route = useLocation();
  const PostData = route?.state?.item;
  const MyToken = localStorage.getItem("TOKEN");
  const handleComment = async () => {
    try {
      // Check if PostData exists and has the necessary properties
      const resp = await axios.post(
        "/post/get-by-id",
        {
          id: PostData?.data?.Postid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      if (resp.data.data) {
        setCommnet(resp?.data?.data[0]?.Comments);
        setDetail(resp?.data?.data[0]?.PostDetail);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    handleComment();
  }, []);
  useEffect(() => {
    handleComment();
  }, [PostData?.data]);

  const handleDelete = (detail) => {
    setPopu(detail);
    setShow(true);
  };
  const navigate = useNavigate();

  const handleData = (items) => {
    const item = {
      data: {
        Postid: items,
      },
      page: "dashboard",
    };
    navigate("/PostView", {
      state: {
        item,
      },
    });
  };

  // Delete API function here..................................................................
  const PostDelete = async () => {
    try {
      const res = await axios.post(
        "/post/delete",
        {
          id: popu.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };
  const Deletepopup = (item) => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Post Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add" style={{ gap: "3rem" }}>
            <div className="flex-delete-popup-refunds">
              <img src={popu.gallery} alt="" className="Two-img-popu-heres" />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{popu?.created_by?.firstname}</span>
                <span>{popu?.created_by?.lastname}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this post?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => PostDelete(item?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />

      <div className="new-add-important-flex">
        <div className="Main-Content-class">
          <div
            className="page-content"
            style={{ marginTop: "2rem", alignItems: "flex-end" }}
          >
            <div
              className="Category-product"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Post Detail
                </a>
                <span>Post</span>
              </div>
            </div>

            {PostData && PostData.page ? (
              <>
                <span className="list-of-products"> POST DEATIL LIST</span>
                <hr className="hr-tag-class" />

                <div className="" style={{ gap: "4rem", width: "100%" }}>
                  <div className="two-big-image-commnet">
                    <div
                      style={{}}
                      className="card"
                      id="first-card-css-re"
                      key={detail.id}
                    >
                      <img
                        src={detail.gallery}
                        alt=""
                        className="diff-img-detail-for"
                      />
                      <button
                        className="delete-buttons-red"
                        onClick={() => {
                          handleDelete(detail);
                        }}
                        style={{
                          background: "red",
                          color: "#fff",
                          marginLeft: "2rem",
                        }}
                      >
                        Delete
                      </button>
                    </div>
                    {/* <hr className="hr-detail-post-def" /> */}
                    <div
                      className="card"
                      // style={{
                      //   width: "70%",
                      //   height: "450px",
                      //   padding: "1.5rem",
                      // }}
                      id="secound-card-css-re"
                    >
                      <div className="Liste-flex-post-detail">
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            <div>{detail?.created_by?.firstname}</div>
                            <div>{detail?.created_by?.lastname}</div>
                          </div>
                          <span style={{ fontSize: "15px", fontWeight: "400" }}>
                            {detail.createdAt}
                          </span>
                        </div>
                        <hr className="hr-redesign-postviews" />
                        <div
                          style={{
                            marginTop: "1rem",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          <span>{detail.desc}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="count-detail-for-apis">
                            <div className="name-api-flex">
                              <span
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                Like
                              </span>
                              <span>{detail.total_like}</span>
                            </div>
                            <div className="name-api-flex">
                              <span
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                Comment
                              </span>
                              <span>{detail.total_comment}</span>
                            </div>
                            <div className="name-api-flex">
                              <span
                                style={{ fontWeight: "600", fontSize: "16px" }}
                              >
                                Repost
                              </span>
                              <span>{detail.total_repost}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* PostComments Design With Api */}
                <div
                  className="page-content"
                  style={{ marginTop: "2rem", alignItems: "flex-end" }}
                >
                  <span
                    className="list-of-products"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    Post Comments
                  </span>
                  <hr className="hr-tag-class" />

                  <div className="table-responsive-add">
                    {commnet.length > 0 ? (
                      commnet.map((currentitem) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2rem",
                            marginTop: "1rem",
                          }}
                          key={currentitem.commentId}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                            // className="justyfy-comment-section"
                          >
                            <div className="justyfy-comment-section">
                              <div style={{ display: "flex", gap: "1rem" }}>
                                <img
                                  src={currentitem.customer.image}
                                  alt=""
                                  className="img-comments-map"
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span style={{ fontSize: "18px" }}>
                                    {currentitem.customer.firstname}
                                    {currentitem.customer.lastname}
                                  </span>
                                  <span style={{ fontSize: "16px" }}>
                                    {currentitem.comments}
                                  </span>
                                </div>
                              </div>
                              <span>{currentitem.createdAt}</span>
                            </div>
                            <hr />
                          </div>
                        </div>
                      ))
                    ) : (
                      <span style={{ textAlign: "center" }}>
                        No comments here
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                className="page-content"
                style={{ marginTop: "2rem", alignItems: "flex-end" }}
              >
                <span
                  className="list-of-products"
                  style={{ fontSize: "18px", fontWeight: "600" }}
                >
                  Post Reason
                </span>
                <hr className="hr-tag-class" />
                <div className="css-view-detail-profile">
                  <div className="Profile-card" id="some-id-for-detailsed">
                    <div className="Name-follw-main">
                      <img
                        src={PostData?.PostGallery}
                        alt=""
                        className="radius-profile-pic"
                        style={{ border: "4px solid red" }}
                      />
                      <span>{PostData?.Buyuser_Name}</span>

                      <div className="btn-main-components">
                        <button
                          className="btn-follow-Name"
                          style={{ background: "red" }}
                          onClick={() => handleData(PostData?.PostId)}
                        >
                          Rename
                        </button>
                      </div>
                    </div>
                    <hr
                      className="hr-tag"
                      style={{ marginTop: "1.5rem", width: "90%" }}
                    />
                    <div className="Main-link-user-profile">
                      <div className="all-link-gaping">
                        <div className="title-and-Name">
                          <span className="span-item-Profile">Reason</span>
                        </div>
                        <span className="span-item-Profile">
                          {PostData?.Reason}
                        </span>
                      </div>
                      <hr className="hr-tag" />
                      <div className="all-link-gaping">
                        <div className="title-and-Name">
                          <span className="span-item-Profile">
                            Reason Message
                          </span>
                        </div>
                        <span className="span-item-Profile">
                          {PostData?.ReasonMsg}
                        </span>
                      </div>
                      <hr className="hr-tag" />
                      <div className="all-link-gaping">
                        <div className="title-and-Name">
                          <span className="span-item-Profile">Title</span>
                        </div>
                        <span className="span-item-Profile">
                          {PostData?.PostDesc}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="table-responsive-add"
                    id="sevnty-percent-detail-view"
                    style={{ padding: "1rem", marginTop: "2rem" }}
                  >
                    <div className="reason-message-flex-map">
                      <div className="view-post-listed">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          SenderName:-
                        </span>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {PostData?.Buyuser_Name}
                        </span>
                      </div>
                      <hr />
                      <div className="view-post-listed">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          Reason:-
                        </span>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {PostData?.Reason}
                        </span>
                      </div>
                      <hr />
                      <div className="view-post-listed">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          ReasonMsg:-
                        </span>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {PostData?.ReasonMsg}
                        </span>
                      </div>
                      <hr />
                      <div className="view-post-listed">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          Title:-
                        </span>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {PostData?.PostDesc}
                        </span>
                      </div>
                      <hr />
                      <div className="view-post-listed">
                        <span style={{ fontSize: "18px", fontWeight: "600" }}>
                          CreatAt:-
                        </span>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {PostData?.CreatAt}
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Postview;
