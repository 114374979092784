import axios from "../Schemas/API";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const ContactList = () => {
  const [list, setList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const MyToken = localStorage.getItem("TOKEN");

  const route = useLocation();
  const postData = route?.state?.item;

  const getList = async () => {
    try {
      const res = await axios.post(
        "/contactus/get-by-id",
        {
          id: postData?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setList(res.data.data);
      // console.log(res, "Listed Fine here");
    } catch (error) {
    }
  };
  useEffect(() => {
    getList();
  }, []);
  // const handlepage = (newpage) => {
  //   const nextPage = Math.max(newpage, 1);
  //   setCurrentpage(nextPage);
  // };
  const navigate = useNavigate();
  return (
    <div className="new-add-important-flex">
      <div className="Main-Content-class">
        <div
          className="page-content"
          style={{ marginTop: "2rem", alignItems: "flex-end" }}
        >
          <div
            className="Category-product"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="three-ones">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ color: "#0a58ca" }}
              >
                <path
                  fill="currentColor"
                  d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                />
              </svg>
              <a
                style={{
                  color: "#008cff",
                  fontWeight: "500",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                href=""
              >
                Contactlist
              </a>
              <span>Category</span>
            </div>
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "red",
                borderRadius: "4px",
                color: "#fff",
                border: "none",
              }}
              onClick={() => navigate("/Contact")}
            >
              Back
            </button>
          </div>
          <hr className="hr-tag-class" />
          <div className="table-responsive-add">
            <div className="Contact-span-flexed">
              <span>Name:-</span>
              <div>
                <span>{list.firstname}</span>
                <span>{list.lastname}</span>
              </div>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>BusinessName:-</span>
              <span>{list.businessname}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Email:-</span>
              <span>{list.email}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Message:-</span>
              <span>{list.message}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>PhoneNumber:-</span>
              <span>{list.phonenumber}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Position:-</span>
              <span>{list.position}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Status:-</span>
              <span>{list.status}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Country:-</span>
              <span>{list.country}</span>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactList;
