import React, { useEffect, useState } from "react";
import "../SignIn/SignIn.css";
import Logo from "../Images/TurkLogo.png";
import { Form } from "react-bootstrap";
import { LoginSchemas } from "../Schemas/Index";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "../Schemas/API";
import { toast } from "react-toastify";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";

// Validation Here..............
const initialValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [store, setStore] = useState("");
  const reqastPermition = async () => {
    const permition = await Notification.requestPermission();
    try {
      if (permition === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BEOklhcJDoHPiTtMUtSwLbAjeydJLrpl-wwO8FriRaFRgDUREjLdbAggtVAgK_dQLy0rAvM_6Sa8rP2Vh1-GQ_Y",
        });
        localStorage.setItem("FCM", token);
      } else {
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    reqastPermition();
  }, []);

  // // getToken API Function........

  const getokens = async () => {
    const getid = localStorage.getItem("FCM");
    setStore(getid);
    try {
      const res = await axios.post(
        "/auth/getToken",
        {
          device_id: store,
          device_type: "web",
        },
        {
          headers: {
            Accept: "application/json",
            "content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("TOKEN", res.data.data.token);
    } catch (error) {
    }
  };

  useEffect(() => {
    getokens();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const MyToken = localStorage.getItem("TOKEN");
    try {
      const Response = await axios.post(
        "auth/signIn",
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      console.log(Response,"Response")
      getokens();
      if (Response.data.status === true) {
        toast.success(Response.data.message);
        localStorage.setItem("token", JSON.stringify(Response));
        navigate("/Dashboard");
      } else {
        toast.error(Response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response && error.response.data
          ? error.response.data.message
          : "An error occurred"
      );
    }
  };

  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LoginSchemas,
      onSubmit,
    });
  const [show, setshow] = useState(false);

  const eyeopen = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 22 22"
    >
      <path
        fill="currentColor"
        d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3c1.641 0 3-1.358 3-3c0-1.641-1.359-3-3-3z"
      />
      <path
        fill="currentColor"
        d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316l-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5c-.504 1.158-2.578 5-7.926 5z"
      />
    </svg>
  );

  const eyeclose = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038c-5.351 0-7.424-3.846-7.926-5a8.642 8.642 0 0 1 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 0 0 0 .633C2.073 12.383 4.367 19 12 19zm0-14c-1.837 0-3.346.396-4.604.981L3.707 2.293L2.293 3.707l18 18l1.414-1.414l-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657a.994.994 0 0 0 0-.633C21.927 11.617 19.633 5 12 5zm4.972 10.558l-2.28-2.28c.19-.39.308-.819.308-1.278c0-1.641-1.359-3-3-3c-.459 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0 1 12 7c5.351 0 7.424 3.846 7.926 5c-.302.692-1.166 2.342-2.954 3.558z"
      />
    </svg>
  );

  return (
    <div className="bg-img-sign-up">
      <section className="Container-Signup-Main">
        <div className="Perent-Signups">
          <img src={Logo} alt="" />
          <div className="card-signup">
            <div className="card-perent">
              <div className="border-signup">
                <h3>Sign in</h3>
                <div className="form-body">
                  <form action="" className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <label htmlFor="inputEmailAddress" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control "
                        name="email"
                        autocomplete="email"
                        autofocus
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}
                    </div>

                    <div className="col-12">
                      <label htmlFor="inputEmailAddress" className="form-label">
                        Email Password
                      </label>
                      <div className="input-group">
                        <div></div>
                        <input
                          style={{ border: "none" }}
                          type={!show ? "password" : "text"}
                          id="password"
                          className="form-control border-end-0"
                          name="password"
                          autocomplete="password"
                          autofocus
                          placeholder="Email Password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <span
                          onClick={() => setshow(!show)}
                          style={{ cursor: "pointer" }}
                        >
                          {!show ? eyeclose : eyeopen}
                        </span>
                      </div>
                      {errors.password && touched.password ? (
                        <p className="form-error">{errors.password}</p>
                      ) : null}
                    </div>

                    <div className="index">
                      <div className="col-md-6">
                        <Form style={{ display: "flex" }}>
                          <Form.Check type="switch" id="custom-switch" />
                          <label
                            htmlFor="flexSwitchCheckChecked"
                            className="form-check-label"
                          >
                            Remember Me
                          </label>
                        </Form>
                      </div>
                      <div className="col-md-6 text-end">
                        <a
                          href=""
                          style={{ color: "red", textDecoration: "none" }}
                        >
                          Forgot Password ?
                        </a>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          style={{
                            height: "56px",
                            border: "none",
                            outline: "none",
                            background: "#da1423",
                            color: "#fff",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textDecoration: "none",
                            fontWeight: "600",
                          }}
                        >
                          {loading ? "Loading...." : <>Sign In</>}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignIn;
