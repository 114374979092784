import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Serch from "../Images/search.png";
import axios from "../Schemas/API";
import Delete from ".././../src/Images/delete.png";
import Edit from ".././../src/Images/edit.png";
import Prev from ".././../src/Images/previous.png";
import Next from ".././../src/Images/next.png";
import { useFormik } from "formik";
import { LableSchemas } from "../Schemas/Index";
const BusinessLanguage = () => {
  // Validation

  const MyToken = localStorage.getItem("TOKEN");
  const [currentpage, setCurrentpage] = useState(1);
  const [totalpages, setTotalPages] = useState(1);

  // GetLabel API Function.............................................................
  const [label, setLabel] = useState([]);

  const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);

    setCurrentpage(nextPage);
  };

  // Delete API Function............................................
  const DeleteLanug = async (labelid) => {
    try {
      const res = await axios.post(
        "/setting/delete-language",
        {
          // id: JSON.stringify(labelid),
          id: labelid.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      getLabel();
    } catch (error) {
      // console.log(error);
    }
  };
  const getLabel = async (page) => {
    try {
      const res = await axios.post(
        "/setting/get-label",
        {
          page: page ? page.toString() : currentpage,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setTotalPages(res.data.totalPages);
      setLabel(res.data.data);
      // DeleteLanug();
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getLabel(currentpage);
  }, [currentpage]);
  return (
    <>
      <div className="new-add-important-flex">
        <div className="Main-Content-class">
          <div className="page-content">
            <div
              className="Category-product"
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "18px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Language
                </a>
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    textDecoration: "none",
                  }}
                >
                  AppLabel
                </span>
              </div>
              <NavLink className="Add-button-cate" to="/AddLanguage">
                Add App Label
              </NavLink>
            </div>
            <span
              className="list-of-products"
              style={{
                fontWeight: "500",
                fontSize: "18px",
                textDecoration: "none",
              }}
            >
              Add App Label
            </span>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div className="serch-beetwin-two">
                <div
                  style={{
                    gap: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <img
                    src={Serch}
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  <input
                    className="form-control form-control-sm"
                    type="Search"
                    style={{
                      borderRadius: "4px",
                      outline: "none",
                      border: "1px solid #ced4da",
                      width: "280px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              </div>

              <div class="card">
                <div class="card-body" style={{ marginTop: "2rem" }}>
                  <div class="table-responsive">
                    <table
                      id="example"
                      class="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "bold" }}>Sr No</th>
                          <th style={{ fontWeight: "bold" }}>Module Name</th>
                          <th style={{ fontWeight: "bold" }}>App Title</th>
                          <th style={{ fontWeight: "bold" }}>App Lable Name</th>
                          <th style={{ fontWeight: "bold" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {label?.map((item, index) => {
                          const serialNumber = index + 1;
                          return (
                            <>
                              <tr>
                                <td>{serialNumber}</td>
                                <td>{item.module_name}</td>
                                <td>{item.labeltitle}</td>
                                <td>{item.labelname}</td>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px solid #4caf50",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      cursor: "pointer",
                                    }}
                                    className="delete-buttons-red"
                                    // onClick={() => ChangeStatus(item?.id)}
                                  >
                                    <img
                                      src={Edit}
                                      alt="Delete"
                                      className="delete-btn-red"
                                    />
                                  </div>
                                  <button
                                    className="delete-buttons-red"
                                    onClick={() => DeleteLanug(item?.label_id)}
                                  >
                                    <img
                                      src={Delete}
                                      alt="Delete"
                                      className="delete-btn-red"
                                    />
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style={{ fontWeight: "bold" }}>Sr No</th>
                          <th style={{ fontWeight: "bold" }}>Module Name</th>
                          <th style={{ fontWeight: "bold" }}>App Title</th>
                          <th style={{ fontWeight: "bold" }}>App Lable Name</th>
                          <th style={{ fontWeight: "bold" }}>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="Page-button-click" style={{ marginTop: "0" }}>
                <div onClick={() => handlepage(currentpage - 1)}>
                  <img
                    src={Prev}
                    alt=""
                    className="both-Next-Prev"
                    style={{ userSelect: "none" }}
                  />
                </div>
                <span style={{ fontSize: "20px", userSelect: "none" }}>
                  {currentpage}
                </span>
                <div
                  onClick={() =>
                    handlepage(
                      totalpages == currentpage ? currentpage : currentpage + 1
                    )
                  }
                >
                  <img
                    src={Next}
                    style={{
                      opacity: totalpages == currentpage ? "0.5" : "1",
                      userSelect: "none",
                    }}
                    alt=""
                    className="both-Next-Prev"
                  />
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessLanguage;
