import { useNavigate } from "react-router";
import axios from "../Schemas/API";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../Language/AddLanguage.css";
import { useFormik } from "formik";
const AddLanguage = () => {
  const MyToken = localStorage.getItem("TOKEN");
  const [language, setLanguage] = useState([]);

  const [selectedModule, setSelectedModule] = useState();
  const [appLabelTitle, setAppLabelTitle] = useState();
  const [categories, setCategories] = useState(
    Array.from({ length: language.length }, () => ({
      language_id: "",
      label_name: "",
    }))
  );
  const navigate = useNavigate();
  const getLang = async () => {
    try {
      const res = await axios.get("/setting/get-language", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MyToken}`,
        },
      });
      setLanguage(res.data.data);
    } catch (error) {
    }
  };
  useEffect(() => {
    getLang();
  }, []);
  const handleInputChange = (index, value, id) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[index] = {
        ...updatedCategories[index],
        language_id: id,
        label_name: value,
        // label_name: id,
        // value: value,
      };
      return updatedCategories;
    });
  };

  const onSubmit = async () => {
    try {
      const resp = await axios.post(
        "/setting/add-label",
        {
          Module: selectedModule,
          LabelTitle: appLabelTitle,
          AppLabel: categories,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      toast.success("AddLabel Added successfully!");
      navigate("/setting-add-label");
    } catch (error) {
    }
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
    // console.log(event.target.value);
  };

  const handleAppLabelTitleChange = (event) => {
    setAppLabelTitle(event.target.value);
  };

  const initialLabelValues = language.reduce((acc, item) => {
    // Assuming item.id is unique and used as the key for initial values
    acc[item.id] = ""; // Initialize each input field value to an empty string  
    return acc;
  }, {});
  const { errors, values, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialLabelValues,
      onSubmit,
    });
  return (
    <>
      <div className="new-add-important-flex">
        <div className="Main-Content-class">
          <div className="page-content-add" style={{ gap: "0" }}>
            <div className="New-add-Categories">
              <span>Create Settings AppLabel</span>
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Setting
                </a>
                <span>Create App Label</span>
              </div>
            </div>
            <div className="card" style={{ marginTop: "1rem", width: "100%" }}>
              <div
                className="card-body"
                style={{
                  borderTop: "3px solid #008cff",
                  borderRadius: "5px",
                }}
              >
                <form className="row-g-3" onSubmit={handleSubmit}>
                  <div className="col-12">
                    <div className="two-diffrent-api">
                      <div
                        style={{
                          display: "flex",
                          gap: "0.4rem",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          className="form-label"
                          // htmlFor={`input-${item.id}`}
                        >
                          Select Module
                        </label>
                        <select
                          class="form-select"
                          id="id-for-last-bg"
                          // style={{ width: "350px", height: "40px" }}
                          aria-label="Default select example"
                          value={selectedModule}
                          name="selectedModule"
                          onChange={handleModuleChange}
                        >
                          <option value="Home">Home</option>
                          <option value="Login">Login</option>
                          <option value="Register">Register</option>
                          <option value="ForgotPassword">
                            Forgot Password
                          </option>
                          <option value="VerifyAccount">Verify Account</option>
                          <option value="MyConnection"> My Connection</option>
                          <option value="Post">Post</option>
                          <option value="MyChat">My Chat</option>
                          <option value="MyProfile">My Profile</option>
                          <option value="Menu">Menu</option>
                          <option value="MyLike">My Like</option>
                          <option value="ChangePassword">
                            Change Password
                          </option>
                          <option value="Settings">Settings</option>
                          <option value="SupportCenter">Support Center</option>
                          <option value="InviteFriends">Invite Friends</option>
                          <option value="MySubcription">My Subcription</option>
                          <option value="MyDeshboard">My Deshboard</option>
                          <option value="General">General</option>
                          <option value="EditProfile">EditProfile</option>
                        </select>
                      </div>
                      <div className="form-flex-adde">
                        <label
                          className="form-label"
                          // htmlFor={`input-${item.id}`}
                        >
                          App Label Title
                        </label>
                        <input
                          style={{ width: "350px", height: "40px" }}
                          required
                          value={appLabelTitle}
                          onChange={handleAppLabelTitleChange}
                          className="form-control"
                          autocomplete="email"
                          autofocus
                        />
                      </div>
                    </div>
                    <div
                      className="grid-class-in-form"
                      style={{ marginTop: "2rem" }}
                    >
                      {language.map((item, index) => {
                        return (
                          <div className="form-flex-adde">
                            <label
                              className="form-label"
                              htmlFor={`input-${item.id}`}
                            >
                              App Label Name in {item.name}
                            </label>
                            <input
                              required
                              type="text"
                              id={`input-${item.id}`}
                              className="form-control"
                              autocomplete="name"
                              name={`input-${item.label_name}`}
                              autofocus
                              value={values[item.id]}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  e.target.value,
                                  item.id
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>

                    <button
                      className="add-Create-button"
                      type="submit"
                      // onsubmit={onSubmit}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLanguage;
