import axios from "../../Schemas/API";
import React, { useEffect, useState } from "react";
import Prev from "../../Images/previous.png";
import Next from "../../Images/next.png";
import Eye from "../../Images/eye.png";
import { useNavigate } from "react-router";
import { MdEdit } from "react-icons/md";

const Invester = () => {
  const [user, setUser] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [serch, setSerch] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  
  const MyToken = localStorage.getItem("TOKEN");
  const getInvester = async (page) => {
    try {
      const res = await axios.post(
        "resources/get-insvester-list",
        {
          page: page.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setUser(res.data.data.rows);
    } catch (error) {
    }
  };
  useEffect(() => {
    getInvester(currentpage, "1");
  }, [currentpage, serch]);
 const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);
    setCurrentpage(nextPage);
  };
  const navigate = useNavigate();
  const handleData = (item) => {
    navigate("/InvesterView", {
      state: {
        item,
      },
    });
  };
  const handleEditInvester = (item) => {
    navigate("/InvesterEdit", {
      state: {
        item,
      },
    });
  };
  return (
    <>
      <div className="new-add-important-flex">
        {/* <Sidebar /> */}
        <div className="Main-Content-class">
          <div className="page-content">
            <div
              className="Category-product"
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Invester
                </a>
              </div>
            </div>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div className="serch-beetwin-two">
                <div
                  style={{
                    gap: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label htmlFor="Search">Search:</label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    id="Search"
                    // value={serch}
                    // onChange={(e) => setSerch(e.target.value)}
                  />
                </div>
              </div>

              <div className="easy-add-scroll-new">
                <div className="card" style={{ marginTop: "2rem" }}>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="example"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Name</th>
                            <th>email</th>
                            <th>Number</th>
                            <th>City</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>Location</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user && user.length > 0 ? (
                            user.map((item, index) => {
                              const serialNumber = index + 1;
                              return (
                                <>
                                  <tr>
                                    <td>{serialNumber}</td>
                                    {/* <div
                                      style={{ display: "flex", gap: "0.5rem" }}
                                    > */}
                                      {/* <div className="responsive-design"> */}
                                        <td>
                                          {item.name}
                                        </td>
                                      {/* </div> */}
                                    {/* </div> */}
                                    <td>{item.email}</td>
                                    {/* <div
                                      style={{ display: "flex", gap: "0.5rem" }}
                                    > */}
                                      <td className="responsive-design">
                                        {item.contact_number}
                                      </td>
                                    {/* </div> */}
                                    <td>{item.City}</td>
                                    <td>{item.Country}</td>
                                    <td>{item.State}</td>
                                    <td>{item.location}</td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "1rem",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <button
                                          className="delete-buttons-red"
                                          style={{
                                            border: "1px solid #15ca20",
                                          }}
                                          onClick={() =>
                                            handleData({
                                              ids: item.id,
                                            })
                                          }
                                          //   onClick={() =>
                                          //     handleData({
                                          //       userId: item.created_by.id,
                                          //       Postid: item.id,
                                          //     })
                                          //   }
                                        >
                                          <img
                                            src={Eye}
                                            alt="Delete"
                                            className="delete-btn-red"
                                          />
                                        </button>
                                        <button
                                          className="delete-buttons-red"
                                          onClick={() =>
                                            handleEditInvester(item)
                                          }
                                          style={{
                                            border: "1px solid goldenrod",
                                          }}
                                          //   onClick={() => {
                                          //     handleShow(item);
                                          //   }}
                                        >
                                          <MdEdit
                                            className="delete-btn-red"
                                            style={{
                                              color: "goldenrod",
                                              marginTop: "0.5rem",
                                            }}
                                          />

                                          {/* <img
                                            src={Edit}
                                            alt="Delete"
                                            className="delete-btn-red"
                                          /> */}
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8">Data Not Found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sr No.</th>
                            <th>Name</th>
                            <th>email</th>
                            <th>Number</th>
                            <th>City</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>Location</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="Page-button-click">
                  <div onClick={() => handlepage(currentpage - 1)}>
                    <img src={Prev} alt="" className="both-Next-Prev" />
                  </div>
                  <span style={{ fontSize: "20px" }}>{currentpage}</span>
                  <div onClick={() => handlepage(currentpage + 1)}>
                    <img src={Next} alt="" className="both-Next-Prev" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invester;


