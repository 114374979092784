import React, { useEffect, useState } from "react";
import "./InvesterEdit.css";
import axios from "axios";
import { useFormik } from "formik";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
const InvesterEdit = () => {
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [founded, setFounded] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const route = useLocation();
  const InvesterData = route?.state?.item;
  const [Detail, setDetail] = useState([]);
  // getCountry API Function...............................................
  const MyToken = localStorage.getItem("TOKEN");
  const getCountry = async () => {
    try {
      const res = await axios.get(
        "https://api.turkafrica.app/api/v1/resources/get-country",
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCountry(res.data.data);
    } catch (error) {
    }
  };
  useEffect(() => {
    getCountry();
  }, []);

  const handleCountryChange = (e) => {
    setCountryId(e.target.value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    uploadimg(formData);
  };
  const uploadimg = async (formData) => {
    try {
      const res = await axios.post(
        "https://api.turkafrica.app/api/v1/common/uploadImage",
        formData,
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPreviewUrl(res.data.data.image_url);
    } catch (error) {
    }
  };
  const initialvalues = {
    title: "",
    email: "",
    number: "",
    location: "",
    country: "",
    state: "",
    city: "",
    founderyear: "",
    logo: "",
  };

  const onSubmit = async () => {
    try {
      const res = await axios.post(
        "https://api.turkafrica.app/api/v1/resources/update-insvester-detail",
        {
          id: InvesterData.id,
          name: title,
          email: email,
          ph_no: number,
          location: location,
          country: countryId,
          state: state,
          city: city,
          founderyear: founded,
          logo: previewUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(res.data.message);
    } catch (error) {
      toast.error(res.data.message);
    }
  };
  const { handleSubmit } = useFormik({
    initialValues: initialvalues,
    onSubmit,
  });

  const getInvesterEdit = async () => {
    try {
      const res = await axios.post(
        "https://api.turkafrica.app/api/v1/resources/get-insvester-detail",
        {
          id: InvesterData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setDetail(res.data.data);
    } catch (error) {
    }
  };
  useEffect(() => {
    getInvesterEdit();
  }, []);
  useEffect(() => {
    if (Detail) {
      setTitle(Detail.name);
      setEmail(Detail.email);
      setNumber(Detail.contact_number);
      setCountryId(Detail.countryId);
      setCity(Detail.City);
      setState(Detail.State);
      setLocation(Detail.location);
      setPreviewUrl(Detail.image);
    }
  }, [Detail]);
  return (
    <div className="new-add-important-flex">
      <div className="Main-Content-class">
        <div
          className="page-content"
          style={{ marginTop: "2rem", alignItems: "flex-end" }}
        >
          <div
            className="Category-product"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="three-ones">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ color: "#0a58ca" }}
              >
                <path
                  fill="currentColor"
                  d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                />
              </svg>
              <a
                style={{
                  color: "#008cff",
                  fontWeight: "500",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                href=""
              >
                InvesterEdit
              </a>
              <span>Invester</span>
            </div>
          </div>
          <hr className="hr-tag-class" />
        </div>
        <form className="" onSubmit={handleSubmit}>
          <div className="main-input-for-edits">
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Name</span>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Email</span>
              <input
                type="email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Phone Number</span>
              <input
                type="tel"
                value={number}
                name="number"
                onChange={(e) => setNumber(e.target.value)}
                className="form-control name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              {/* <span className="span-name-invester">Select Country</span>
              <input type="text" className="form-control name-input-same-css" /> */}
              <label className="form-label">Select Country</label>
              <select
                className="form-control name-input-same-css"
                onChange={handleCountryChange}
                value={countryId}
                required
              >
                <option value="">Select Country</option>
                {country.map((itm, index) => (
                  <option key={index} value={itm.id}>
                    {itm.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">City</span>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="form-control name-input-same-css"
                name="city"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">State</span>
              <input
                type="text"
                value={state}
                name="state"
                onChange={(e) => setState(e.target.value)}
                className="form-control name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Locaiton</span>
              <input
                type="text"
                value={location}
                name="location"
                onChange={(e) => setLocation(e.target.value)}
                className="form-control name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Founded</span>
              <input
                type="text"
                value={founded}
                name="founded"
                onChange={(e) => setFounded(e.target.value)}
                className="form-control name-input-same-css"
              />
            </div>
          </div>
          <div
            className="create-select-img-main"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label className="form-label">Image </label>

            <input
              style={{ width: "30%" }}
              onChange={handleFileChange}
              type="file"
              accept="image/*"
              name="news_image"
              required
            />
            <div
              className={`image-container-secound--cate ${
                previewUrl ? "selected" : ""
              }`}
            >
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt="Selected"
                  name="news_image"
                  className="select-img-here"
                />
              )}
            </div>
          </div>
          <button type="submit" className="update-btn-inexter">
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default InvesterEdit;
