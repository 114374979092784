import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "../Schemas/API";
import "../Post/Comment.css";
const Comment = () => {
  const route = useLocation();
  const PostData = route?.state?.item;
  const [commnet, setCommnet] = useState([]);
  const MyToken = localStorage.getItem("TOKEN");

  const handleComment = async () => {
    try {
      const resp = await axios.post(
        "/post/get-by-id",
        {
          id: PostData.Postid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setCommnet(resp.data.data[0].Comments);
    } catch (error) {
    }
  };
  useEffect(() => {
    handleComment();
  });
  return (
    <div
      className="table-responsive-add"
      style={{
        marginTop: "8rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* {commnet.map((currentitem) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Comments
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", justifyContent: "spa" }}>
                <span style={{ fontSize: "18px" }}>{currentitem.comments}</span>
                <span>{currentitem.createdAt}</span>
              </div>
              <hr />
            </div>
          </div>
        );
      })} */}
      <span style={{ fontSize: "20px", fontWeight: "600" }}>Comments</span>
      {commnet.length > 0 ? (
        commnet.map((currentitem) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              marginTop: "1rem",
            }}
            key={currentitem.commentId}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="justyfy-comment-section">
                <span style={{ fontSize: "18px" }}>{currentitem.comments}</span>
                <span>{currentitem.createdAt}</span>
              </div>
              <hr />
            </div>
          </div>
        ))
      ) : (
        <span style={{ textAlign: "center" }}>No comments here</span>
      )}
    </div>
  );
};

export default Comment;
